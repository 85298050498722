.Creator{
    position: absolute;
    top: 0;
    left: 0px;
    height:100vh;
    padding-top: 1320px;
    z-index: 999999;
    width: 100%;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;    
}
.creator svg{
    user-select: none;    
}
.creatorqr{
    position: absolute;
    top: 1650px;
    left: 710px;
    transform: scale(0.7)
}

#Group-Copy-2{
    cursor: pointer;
}

.creator-step-1{
    transform: scale(0.7)
}
.creator-step-2{
    transform: scale(0.7)
}
.creator-step-3{
    transform: scale(0.7)
}
.creator-step-4{
    transform: scale(0.7)
}

.pointer{
    cursor: pointer;
}