._1ceqH{
  position: absolute;
  z-index: 999;
  bottom: 0;
  width: 300px;
}
.bio{
  position: absolute;
  z-index: 999;
  top: 150px;
  left: 50%;
  width: 70%;
  transform: translate(-50%,-50%);
}

.descuento{
  position: absolute;
    z-index: 9999;
    top: 1310px;
    left: 10px;
    padding: 20px;    
    text-align: center;
    transform: scale(1.8);
 
}

.descuento img{
  position: absolute;
}

.descuento .qr{
  top: 91px;
  left: 84px;
  border-radius: 10px;
}

.sombra{
  position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    opacity: 0.5;
}

#recording{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
}
